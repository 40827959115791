import { createSlice } from "@reduxjs/toolkit";
import { HotelFive, HotelFour, HotelOne, HotelThree } from "../../Images";

const ContentSlice = createSlice({
  name: "content",
  initialState: {
    homePage: [
      {
        name: "Office",
        images: [HotelThree, HotelFour, HotelFive],
        heroImg: HotelOne,
        url: "/office",
      },
      {
        name: "Kitchen",
        images: [HotelThree, HotelFour, HotelFive],
        heroImg: HotelFour,
        url: "/kitchen",
      },
      {
        name: "False Celling",
        images: [HotelThree, HotelFour, HotelFive],
        heroImg: HotelOne,
        url: "/false-celling",
      },
      {
        name: "Flat Interior",
        images: [HotelThree, HotelFour, HotelFive],
        heroImg: HotelFive,
        url: "/flat-interior-design",
      },
      {
        name: "Modern Bathroom",
        images: [HotelThree, HotelFour, HotelFive],
        heroImg: HotelFive,
        url: "/modern-bathroom",
      },
    ],
  },

  reducers: {},
});

export const {} = ContentSlice.actions;

export default ContentSlice.reducer;
