import React from "react";
import {
  Home,
  FalseCelling,
  Kitchen,
  Logo,
  Office,
  flatInteriorDesign,
  ModernBathroom,
} from "../../Images";
import $ from "jquery";
import { FaPhoneVolume } from "react-icons/fa6";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

const Header = () => {
  function headerStuff() {
    if ($(window).scrollTop() > 50) {
      $("header").addClass("sticky-header");
    } else {
      $("header").removeClass("sticky-header");
    }
  }
  $(document).ready(function () {
    headerStuff();
    $(window).scroll(function () {
      headerStuff();
    });
  });
  return (
    <>
      <header className="header">
        <div className="site-navbar">
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col-6 col-sm-6 col-md-2 col-lg-2 col-xl-3 main-logo">
                <div className="my-0 site-logo">
                  <Link to="/">
                    <img src={Logo} alt="logo" />
                  </Link>
                </div>
              </div>
              <div className="col-md-10 col-lg-10 col-xl-9 tab_hide">
                <div className="site-navigation" role="navigation">
                  <ul className="m-0 p-0">
                    <li>
                      <Link to="/" className="nav-link">
                        Home
                      </Link>
                    </li>
                    <li className="sub">
                      <Link to="" className="nav-link">
                        Service
                      </Link>
                      <div className="sub_menu">
                        <div className="row h-100 align-items-center">
                          <div className="col">
                            <Link to="/service-home">
                              <img src={Home} alt="" />
                              <p className="mb-0">Home</p>
                            </Link>
                          </div>
                          <div className="col">
                            <Link to="/office">
                              <img src={Office} alt="" />
                              <p className="mb-0">Office</p>
                            </Link>
                          </div>
                          <div className="col">
                            <Link to="/kitchen">
                              <img src={Kitchen} alt="" />
                              <p className="mb-0">Kitchen</p>
                            </Link>
                          </div>
                          <div className="col">
                            <Link to="/false-celling">
                              <img src={FalseCelling} alt="" />
                              <p className="mb-0">False Celling</p>
                            </Link>
                          </div>
                          <div className="col">
                            <Link to="/flat-interior-design">
                              <img src={flatInteriorDesign} alt="" />
                              <p className="mb-0">Flat Interior Design</p>
                            </Link>
                          </div>
                          <div className="col">
                            <Link to="/modern-bathroom">
                              <img src={ModernBathroom} alt="" />
                              <p className="mb-0">Modern Bathroom</p>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li>
                      <Link to="/portfolio" className="nav-link">
                        Portfolio
                      </Link>
                    </li>
                    <li>
                      <Link to="/about" className="nav-link">
                        About
                      </Link>
                    </li>
                    <li>
                      <Link to="/blog" className="nav-link">
                        Blog
                      </Link>
                    </li>
                    <li>
                      <HashLink smooth to="/#contact_sec" className="nav-link">
                        Contact Us
                      </HashLink>
                    </li>
                    <li>
                      <Link to="tel:9836160115" className="nav-link call-us">
                        <FaPhoneVolume />
                        Call Us
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
