import React from "react";
import kitchenBg from "../Images/kitchen-bg.jpg";

const Blog = () => {
  return (
    <div>
      <div
        className="page-header"
        style={{ backgroundImage: `url(${kitchenBg})` }}
      >
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="page-header-box">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/">Home</a>
                  </li>
                  <li className="breadcrumb-item active">Blog</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="blog_bg">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="post-box">
                <div className="post-inner">
                  <div className="post_pic">
                    <img src={kitchenBg} alt="" />
                  </div>
                  <div className="inner-post">
                    <div className="entry-header">
                      <span>March 19, 2020</span>
                      <h5>
                        <a href="/">
                          Top 10 Tips for Your Kitchen Interior Design
                        </a>
                      </h5>
                    </div>
                    <div className="the-excerpt">
                      <p>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Quaerat numquam repudiandae dolorum eius fugit voluptas
                        ipsum quibusdam,{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="post-box">
                <div className="post-inner">
                  <div className="post_pic">
                    <img src={kitchenBg} alt="" />
                  </div>
                  <div className="inner-post">
                    <div className="entry-header">
                      <span>March 19, 2020</span>
                      <h5>
                        <a href="/">
                          Top 10 Tips for Your Kitchen Interior Design
                        </a>
                      </h5>
                    </div>
                    <div className="the-excerpt">
                      <p>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Quaerat numquam repudiandae dolorum eius fugit voluptas
                        ipsum quibusdam,{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="post-box">
                <div className="post-inner">
                  <div className="post_pic">
                    <img src={kitchenBg} alt="" />
                  </div>
                  <div className="inner-post">
                    <div className="entry-header">
                      <span>March 19, 2020</span>
                      <h5>
                        <a href="/">
                          Top 10 Tips for Your Kitchen Interior Design
                        </a>
                      </h5>
                    </div>
                    <div className="the-excerpt">
                      <p>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Quaerat numquam repudiandae dolorum eius fugit voluptas
                        ipsum quibusdam,{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="post-box">
                <div className="post-inner">
                  <div className="post_pic">
                    <img src={kitchenBg} alt="" />
                  </div>
                  <div className="inner-post">
                    <div className="entry-header">
                      <span>March 19, 2020</span>
                      <h5>
                        <a href="/">
                          Top 10 Tips for Your Kitchen Interior Design
                        </a>
                      </h5>
                    </div>
                    <div className="the-excerpt">
                      <p>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Quaerat numquam repudiandae dolorum eius fugit voluptas
                        ipsum quibusdam,{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="post-box">
                <div className="post-inner">
                  <div className="post_pic">
                    <img src={kitchenBg} alt="" />
                  </div>
                  <div className="inner-post">
                    <div className="entry-header">
                      <span>March 19, 2020</span>
                      <h5>
                        <a href="/">
                          Top 10 Tips for Your Kitchen Interior Design
                        </a>
                      </h5>
                    </div>
                    <div className="the-excerpt">
                      <p>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Quaerat numquam repudiandae dolorum eius fugit voluptas
                        ipsum quibusdam,{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="post-box">
                <div className="post-inner">
                  <div className="post_pic">
                    <img src={kitchenBg} alt="" />
                  </div>
                  <div className="inner-post">
                    <div className="entry-header">
                      <span>March 19, 2020</span>
                      <h5>
                        <a href="/">
                          Top 10 Tips for Your Kitchen Interior Design
                        </a>
                      </h5>
                    </div>
                    <div className="the-excerpt">
                      <p>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Quaerat numquam repudiandae dolorum eius fugit voluptas
                        ipsum quibusdam,{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Blog;
