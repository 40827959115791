import React from "react";
import {
  BedroomFive,
  BedroomFour,
  BedroomOne,
  BedroomSix,
  BedroomThree,
  BedroomTwo,
  HotelFive,
  HotelFour,
  HotelThree,
  InnerImg,
} from "../../Images";
import kitchenBg from "../../Images/kitchen-bg.jpg";

const KitchenMain = () => {
  return (
    <>
      <div
        className="page-header"
        style={{ backgroundImage: `url(${kitchenBg})` }}
      >
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="page-header-box">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/">Home</a>
                  </li>
                  <li className="breadcrumb-item active">Kitchen</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="home-design pt-5">
        <div className="container-fluid">
          <div className="row no-gutters">
            <div className="col-sm-3 col-6">
              <a href="/">
                <img src={BedroomOne} className="small_img" alt="" />
              </a>
            </div>
            <div className="col-sm-3 col-6">
              <a href="/">
                <img src={BedroomTwo} className="small_img" alt="" />
              </a>
            </div>
            <div className="col-sm-3 col-6">
              <a href="/">
                <img src={BedroomThree} className="small_img" alt="" />
              </a>
            </div>
            <div className="col-sm-3 col-6">
              <a href="/">
                <img src={BedroomFour} className="small_img" alt="" />
              </a>
            </div>
            <div className="col-sm-6 col-12">
              <a href="/">
                <img src={BedroomFive} className="big_img" alt="" />
              </a>
            </div>
            <div className="col-sm-6 col-12">
              <a href="/">
                <img src={BedroomSix} className="big_img" alt="" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <section className="services_inner">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-12 col-xl-5 mt-4 mt-xl-0">
              <div className="services-about-img">
                <img src={InnerImg} alt="node-js-development" />
              </div>
            </div>
            <div className="col-12 col-md-12 col-xl-7 align-self-center">
              <h2>Lorem, ipsum dolor sit amet consectetur adipisicing elit.</h2>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Neque
                fugiat beatae nobis, eaque, optio cupiditate, quasi amet error
                animi nemo vitae ipsum pariatur aliquam accusantium
                necessitatibus natus ea quidem iusto. Lorem ipsum dolor sit amet
                consectetur adipisicing elit. Neque fugiat beatae nobis, eaque,
                optio cupiditate, quasi amet error animi nemo vitae ipsum
                pariatur aliquam accusantium necessitatibus natus ea quidem
                iusto.
              </p>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Neque
                fugiat beatae nobis, eaque, optio cupiditate, quasi amet error
                animi nemo vitae ipsum pariatur aliquam accusantium
                necessitatibus natus ea quidem iusto. Lorem ipsum dolor sit amet
                consectetur adipisicing elit.
              </p>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Neque
                fugiat beatae nobis, eaque, optio cupiditate, quasi amet error
                animi nemo vitae ipsum pariatur aliquam accusantium
                necessitatibus natus ea quidem iusto. Lorem ipsum dolor sit amet
                consectetur adipisicing elit. Neque fugiat beatae nobis, eaque,
                optio cupiditate, quasi amet error animi nemo vitae ipsum
                pariatur aliquam accusantium necessitatibus natus ea quidem
                iusto.
              </p>
            </div>
          </div>
        </div>
      </section>
      <div className="home-design hotel-design">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 text-center">
              <h3>Kitchen Showcase Design</h3>
            </div>
          </div>
          <div className="row no-gutters">
            <div className="col-sm-8 col-12">
              <div className="slide-animation">
                <img src={HotelThree} className="big_img" alt="" />
                <a href="/">
                  <div className="description-wrapper">
                    <p>Inspiration</p>
                    <h5>Table Design</h5>
                  </div>
                </a>
              </div>
              <div className="slide-animation">
                <img src={HotelFour} className="big_img" alt="" />
                <a href="/">
                  <div className="description-wrapper">
                    <p>Inspiration</p>
                    <h5>Table Design</h5>
                  </div>
                </a>
              </div>
            </div>
            <div className="col-sm-4 col-6">
              <div className="slide-animation">
                <img src={HotelFive} className="small_img" alt="" />
                <a href="/">
                  <div className="description-wrapper">
                    <p>Inspiration</p>
                    <h5>Table Design</h5>
                  </div>
                </a>
              </div>
              <div className="slide-animation">
                <img src={HotelFive} className="small_img" alt="" />
                <a href="/">
                  <div className="description-wrapper">
                    <p>Inspiration</p>
                    <h5>Table Design</h5>
                  </div>
                </a>
              </div>
              <div className="slide-animation">
                <img src={HotelFive} className="small_img" alt="" />
                <a href="/">
                  <div className="description-wrapper">
                    <p>Inspiration</p>
                    <h5>Table Design</h5>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="services_inner">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 col-md-12 col-xl-7 align-self-center">
              <h2>Lorem, ipsum dolor sit amet consectetur adipisicing elit.</h2>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Neque
                fugiat beatae nobis, eaque, optio cupiditate, quasi amet error
                animi nemo vitae ipsum pariatur aliquam accusantium
                necessitatibus natus ea quidem iusto. Lorem ipsum dolor sit amet
                consectetur adipisicing elit. Neque fugiat beatae nobis, eaque,
                optio cupiditate, quasi amet error animi nemo vitae ipsum
                pariatur aliquam accusantium necessitatibus natus ea quidem
                iusto.
              </p>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Neque
                fugiat beatae nobis, eaque, optio cupiditate, quasi amet error
                animi nemo vitae ipsum pariatur aliquam accusantium
                necessitatibus natus ea quidem iusto. Lorem ipsum dolor sit amet
                consectetur adipisicing elit.
              </p>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Neque
                fugiat beatae nobis, eaque, optio cupiditate, quasi amet error
                animi nemo vitae ipsum pariatur aliquam accusantium
                necessitatibus natus ea quidem iusto. Lorem ipsum dolor sit amet
                consectetur adipisicing elit. Neque fugiat beatae nobis, eaque,
                optio cupiditate, quasi amet error animi nemo vitae ipsum
                pariatur aliquam accusantium necessitatibus natus ea quidem
                iusto.
              </p>
            </div>
            <div className="col-12 col-md-12 col-xl-5 mt-4 mt-xl-0">
              <div className="services-about-img">
                <img src={InnerImg} alt="node-js-development" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="home-design hotel-design">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 text-center">
              <h3>Modular Kitchen Design</h3>
            </div>
          </div>
          <div className="row no-gutters">
            <div className="col-sm-4 col-6">
              <div className="slide-animation">
                <img src={HotelFive} className="small_img" alt="" />
                <a href="/">
                  <div className="description-wrapper">
                    <p>Inspiration</p>
                    <h5>Table Design</h5>
                  </div>
                </a>
              </div>
              <div className="slide-animation">
                <img src={HotelFive} className="small_img" alt="" />
                <a href="/">
                  <div className="description-wrapper">
                    <p>Inspiration</p>
                    <h5>Table Design</h5>
                  </div>
                </a>
              </div>
              <div className="slide-animation">
                <img src={HotelFive} className="small_img" alt="" />
                <a href="/">
                  <div className="description-wrapper">
                    <p>Inspiration</p>
                    <h5>Table Design</h5>
                  </div>
                </a>
              </div>
            </div>
            <div className="col-sm-8 col-12">
              <div className="slide-animation">
                <img src={HotelThree} className="big_img" alt="" />
                <a href="/">
                  <div className="description-wrapper">
                    <p>Inspiration</p>
                    <h5>Table Design</h5>
                  </div>
                </a>
              </div>
              <div className="slide-animation">
                <img src={HotelFour} className="big_img" alt="" />
                <a href="/">
                  <div className="description-wrapper">
                    <p>Inspiration</p>
                    <h5>Table Design</h5>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="services_inner">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 col-md-12 col-xl-5 mt-4 mt-xl-0">
              <div className="services-about-img">
                <img src={InnerImg} alt="node-js-development" />
              </div>
            </div>
            <div className="col-12 col-md-12 col-xl-7 align-self-center">
              <h2>Lorem, ipsum dolor sit amet consectetur adipisicing elit.</h2>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Neque
                fugiat beatae nobis, eaque, optio cupiditate, quasi amet error
                animi nemo vitae ipsum pariatur aliquam accusantium
                necessitatibus natus ea quidem iusto. Lorem ipsum dolor sit amet
                consectetur adipisicing elit. Neque fugiat beatae nobis, eaque,
                optio cupiditate, quasi amet error animi nemo vitae ipsum
                pariatur aliquam accusantium necessitatibus natus ea quidem
                iusto.
              </p>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Neque
                fugiat beatae nobis, eaque, optio cupiditate, quasi amet error
                animi nemo vitae ipsum pariatur aliquam accusantium
                necessitatibus natus ea quidem iusto. Lorem ipsum dolor sit amet
                consectetur adipisicing elit.
              </p>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Neque
                fugiat beatae nobis, eaque, optio cupiditate, quasi amet error
                animi nemo vitae ipsum pariatur aliquam accusantium
                necessitatibus natus ea quidem iusto. Lorem ipsum dolor sit amet
                consectetur adipisicing elit. Neque fugiat beatae nobis, eaque,
                optio cupiditate, quasi amet error animi nemo vitae ipsum
                pariatur aliquam accusantium necessitatibus natus ea quidem
                iusto.
              </p>
            </div>
          </div>
        </div>
      </section>
      <div className="home-design hotel-design">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 text-center">
              <h3>Kitchen Room Design</h3>
            </div>
          </div>
          <div className="row no-gutters">
            <div className="col-sm-8 col-12">
              <div className="slide-animation">
                <img src={HotelThree} className="big_img" alt="" />
                <a href="/">
                  <div className="description-wrapper">
                    <p>Inspiration</p>
                    <h5>Table Design</h5>
                  </div>
                </a>
              </div>
              <div className="slide-animation">
                <img src={HotelFour} className="big_img" alt="" />
                <a href="/">
                  <div className="description-wrapper">
                    <p>Inspiration</p>
                    <h5>Table Design</h5>
                  </div>
                </a>
              </div>
            </div>
            <div className="col-sm-4 col-6">
              <div className="slide-animation">
                <img src={HotelFive} className="small_img" alt="" />
                <a href="/">
                  <div className="description-wrapper">
                    <p>Inspiration</p>
                    <h5>Table Design</h5>
                  </div>
                </a>
              </div>
              <div className="slide-animation">
                <img src={HotelFive} className="small_img" alt="" />
                <a href="/">
                  <div className="description-wrapper">
                    <p>Inspiration</p>
                    <h5>Table Design</h5>
                  </div>
                </a>
              </div>
              <div className="slide-animation">
                <img src={HotelFive} className="small_img" alt="" />
                <a href="/">
                  <div className="description-wrapper">
                    <p>Inspiration</p>
                    <h5>Table Design</h5>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default KitchenMain;
